@import 'src/scss/variables';
@import 'src/scss/palette';
@import 'src/scss/bootstrap/variables';

.main-menu {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $light-gray;
    font-family: $font-family-sans-serif;
    top: 0;
    width: 100%;
    background: $white;
    z-index: 12;
    background-color: $white;
    overflow-y: hidden;

    .logo {
        padding: 2rem 2rem;

        img {
            width: 20em;
            height: 100%;
        }
    }

    .hamburger {
        display: none;
    }

    .items {
        padding: 0 1.5rem;
        display: flex;
        height: 100%;

        a {
            font-size: $h4-size;
            color: $dark-gray;
        }

        .selected {
            color: $green;
        }
    }

    .ml-account {
        margin-left: auto;
    }

    .account-default {
        display: block;
        border-radius: 50%;
        background: $soft-gray;
        padding-top: 0.38rem;
        padding-bottom: 0.38rem;
        margin-right: 2rem;

        .image {
            background-image: url('../../assets/svg/SvgProfile.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            display: block;
            width: 50px;
            height: 40px;
            opacity: 0.5;
        }
    }

    .account {
        display: block;
        padding-top: 0.38rem;
        padding-bottom: 0.38rem;
        margin-right: 2rem;

        width: 50px;
        height: 50px;

        border-radius: 25px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .image {
            display: block;
            width: auto;
            height: 50px;
            border-radius: 50%;
        }
    }

    .dropbtn {
        padding: 0;
        background: none;
        border: 0;
        font-size: $h4-size;
        color: $dark-gray;
    }

    .dropdown {
        font-size: $h4-size;
        position: static;
        display: inline-block;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: $white;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba($black, 0.2);
        z-index: 30;
    }

    .dropdown-content a {
        color: $dark-gray;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

    .dropdown-content a:hover {
        background-color: $soft-gray;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }

    .dropdown-content .items:hover {
        background-color: $soft-gray;
    }

    .navbar {
        position: static;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
    }
}

.mobile-menu {
    display: none;

    .items {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.8rem;
        width: 100%;

        a {
            color: $dark-gray;
        }
    }

    .close {
        display: block;
        cursor: pointer;
        background-image: url('../../assets/svg/SvgCross.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin-right: 20px;
        width: 22px;
        height: 22px;
    }
}

@keyframes rodal-slideDown-enter {
    from {
        -webkit-transform: translate3d(0, -100px, 0);
        transform: translate3d(0, -100px, 0);
    }
}

.rodal-slideDown-enter {
    -webkit-animation: rodal-slideDown-enter both cubic-bezier(0.4, 0, 0, 1.5);
    animation: rodal-slideDown-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@media screen and (max-width: $mobile-size) {
    .main-menu {
        height: $mobile-main-menu-height;
        width: 100%;
        z-index: 16;
        position: fixed;
        background: $white;
        transform: translateZ(0);
        &.banner {
            top: 50px;
        }

        .logo {
            padding: 0;
            margin: auto;

            img {
                width: 190px;
            }
        }

        .hamburger {
            display: block;
            cursor: pointer;
            background: url('../../assets/svg/SvgBarsSolid.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: 10px;
            width: 22px;
            height: 22px;
        }

        .items {
            display: none;
        }

        .account-default {
            margin-left: 0;

            .image {
                background: url('../../assets/svg/SvgProfile.svg');
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                width: 35px;
                height: 25px;
                border-radius: 50%;
            }
        }
        .account {
            margin-left: 0;
            width: 35px;
            height: 35px;
        }

        .dropdown {
            display: none;
        }

        .navbar {
            display: none;
        }
    }

    .mobile-menu {
        animation-duration: 1000ms;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 16;
        display: block;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        height: 100%;
        padding: 0;
        padding-top: 15px;
        background: $white;
        box-sizing: border-box;
        transition: all 300ms cubic-bezier(0, 0.085, 0.68, 0.53);
        font-family: $font-family-sans-serif;
    }
}

.host-menu {
    font-family: 'Work Sans';
    height: 120px;
    padding: 20px;
    border-bottom: 0;

    .items.separator {
        padding-left: 0;
        padding-right: 0;
    }

    .dropdown-menu-host {
        position: absolute;
        right: 38px;
        top: 105px;
        border: 1px solid $soft-gray;
        border-radius: 5px;

        background-color: $white;

        padding: 10px 15px;

        width: 220px;
        z-index: 20;

        .items {
            padding: 9px 0;

            &.separator {
                padding: 5px 0;
                margin-bottom: 5px;
            }

            a {
                color: $dark-gray;
                font-size: 14px;
            }

            &.separator {
                border-bottom: 1px solid $fog-gray;
            }

            &:after {
                top: 15px;
                right: 0;
            }
        }

        &:after {
            content: '';
            border: 1px solid $soft-gray;
            background-color: $white;

            position: absolute;
            top: -11px;
            right: 15px;

            width: 20px;
            height: 20px;

            border-right: none;
            border-bottom: none;
            transform: rotate(45deg);
        }
    }

    .account-default,
    .account {
        margin-right: 20px;
        margin-left: 0;
        position: relative;

        &.menu-close {
            &:after {
                content: '';
                position: absolute;
                background: url('../../assets/svg/SvgChevronDownSolid.svg');
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                width: 14px;
                height: 9px;
                right: -24px;
                top: 18px;
            }
        }

        &.menu-open {
            &:after {
                content: '';
                position: absolute;
                background: url('../../assets/svg/SvgCross2.svg');
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                width: 16px;
                height: 16px;

                right: -20px;
                top: 15px;
            }
        }
    }

    .account {
        width: 50px;
        height: 50px;

        border-radius: 25px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .logo {
        margin: 0;
        padding: 0;
        margin-top: -10px;

        img {
            width: 186px;
        }
    }
    .hamburger {
        margin-left: 0;
        width: 25px;
        height: 25px;
    }
    .close {
        display: block;
        cursor: pointer;
        background: url('../../assets/svg/SvgCross.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: -2px;
        width: 30px;
        height: 30px;
    }
}

@media screen and (max-width: $mobile-size) {
    .host-menu {
        height: 65px;
        padding-right: 20px;
        border-bottom: 1px solid $soft-gray;

        .logo {
            margin: 0;

            img {
                width: 40px;
            }
        }
    }

    .main-menu {
        .ml-account {
            margin-left: 0;
        }
    }
}

.mobile-menu.host-menu {
    top: 65px;
    height: auto;
    font-weight: normal;
    font-size: 20px;
    padding: 10px;

    color: $medium-gray;
    height: calc(100% - 65px);
}

.mobile-menu.host-menu,
.dropdown-menu-host {
    .items {
        padding: 10px 15px;

        a {
            color: $medium-gray;
        }

        display: block;
        position: relative;
        &::after {
            content: '';
            width: 10px;
            height: 10px;
            border: 2px solid map-get($map-green, '200');
            border-bottom: none;
            border-radius: 2px;
            border-left: none;
            transform: rotate(45deg);
            position: absolute;
            right: 15px;
            top: 20px;
        }

        &:hover,
        &:active {
            background-color: $snow-white;
            a {
                // color: $green;
                text-decoration: none;
            }

            // &::after {
            //   border-color: $green;
            // }
        }

        &.green {
            font-weight: 600;

            a {
                color: $green;
            }
        }

        &.separator {
            border-bottom: 1px solid map-get($map-green, '200');
            margin-bottom: 22px;

            &::after {
                display: none;
            }
        }

        /*
        &.underlined {
        }
        */
        &:nth-child(1) {
            padding-top: 24px;

            &::after {
                top: 34px;
            }
        }
    }
}

.dropdown-menu-host {
    .items {
        &:nth-child(1) {
            padding-top: 10px;

            &::after {
                top: 15px;
            }
        }
    }
}
