@import 'src/scss/functions';
@import 'src/scss/variables';
@import 'src/scss/palette';

.app-download-modal {
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: 1000;
    }

    .content_logo-image {
        margin: 0 auto 24px;
        width: 60px;
        height: 60px;
        object-fit: cover;
        object-position: center;
    }

    .content {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background: $white;
        border-radius: 16px 16px 0 0;
        z-index: 1001;
        padding: snif-grid(2);

        &_close {
            position: absolute;
            top: snif-grid(2);
            left: snif-grid(2);
            padding: 0;
            border: none;
            background: transparent;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: snif-grid(3);
            height: snif-grid(3);

            svg {
                width: 24px;
                height: 24px;
                color: $medium-gray;
            }

            &:hover {
                svg {
                    color: darken($medium-gray, 10%);
                }
            }
        }

        &_container {
            text-align: center;
            padding: snif-grid(4) snif-grid(2) snif-grid(2);
        }

        &_header {
            margin-bottom: snif-grid(4);

            h2 {
                font-size: 24px;
                line-height: 32px;
                margin-bottom: snif-grid(1);
                color: $dark-gray;
            }

            p {
                font-size: 17px;
                line-height: 24px;
                color: $medium-gray;
                margin: 0;
            }
        }

        &_body {
            display: flex;
            flex-direction: column;
            gap: snif-grid(2);
            padding: 0 snif-grid(1);

            .snif-button {
                width: 100%;
                border: none;
                border-radius: 8px;
                font-size: 17px;
                line-height: 24px;
                padding: snif-grid(1.5);
                font-weight: 500;

                &--primary {
                    background-color: $green;
                    color: $white;

                    &:hover {
                        background-color: darken($green, 5%);
                    }
                }

                &--text {
                    background: none;
                    color: $medium-gray;
                    padding: snif-grid(1);

                    &:hover {
                        color: darken($medium-gray, 10%);
                    }
                }
            }
        }
    }
}
